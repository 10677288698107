<template>
  <div class="d-flex d-flex-c d-flex-between flex-1">
    <div
      class="d-flex d-flex-r d-flex-between d-align-content-start section one"
    >
      <chart-wrapper
        label="Impressions"
        wrapper-type="small-c"
        :chart-config="impressionsConf"
      />
      <chart-wrapper
        class="ml-1"
        label="Reach"
        wrapper-type="small-c"
        :chart-config="reachConf"
      />
      <chart-wrapper
        class="ml-1"
        label="New likes"
        wrapper-type="small-c"
        :chart-config="newLikesConf"
      />
      <chart-wrapper
        class="ml-1"
        label="Current page likes"
        wrapper-type="small-c"
        :chart-config="currentLikesConf"
      />
    </div>
    <div
      class="d-flex d-flex-r d-flex-between d-align-content-start section two"
    >
      <chart-wrapper
        label="Impressions"
        chart-type="area"
        wrapper-type="large f-height"
        :chart-config="impressionsSeries"
      />
      <chart-wrapper
        class="ml-1"
        chart-type="line"
        label="Reach"
        wrapper-type="large f-height"
        :chart-config="reachSeries"
      />
    </div>
    <div
      class="d-flex d-flex-r d-flex-between d-align-content-start section two"
    >
      <chart-wrapper
        label="New Likes by day"
        chart-type="bar"
        wrapper-type="large f-height"
        :chart-config="newLikesSeries"
      />
      <chart-wrapper
        class="ml-1"
        chart-type="line"
        label="Page unlikes"
        wrapper-type="large f-height"
        :chart-config="unlikeSeries"
      />
    </div>
    <div
      class="d-flex d-flex-r d-flex-between d-align-content-start section two"
    >
      <chart-wrapper
        label="Visits"
        chart-type="line"
        wrapper-type="large f-height"
        :chart-config="visitsSeries"
      />
      <div
        class="d-flex d-flex-r flex-wrap ml-1"
        style="flex: 1.055; height: 400px"
      >
        <chart-wrapper
          label="Reactions"
          chart-type="area"
          wrapper-type="medium -h"
          :chart-config="postReactionsSeries"
        />
        <chart-wrapper
          class="ml-1"
          label="Comments"
          chart-type="area"
          wrapper-type="medium -h"
          :chart-config="pageCommentsSeries"
        />
        <chart-wrapper
          label="Shares"
          chart-type="area"
          wrapper-type="medium -h"
          :chart-config="pageSharesSeries"
        />
        <chart-wrapper
          class="ml-1"
          label="Post link clicks"
          chart-type="area"
          wrapper-type="medium -h"
          :chart-config="postLinkSeries"
        />
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "@/utils/timezone";

import { areaChartOpts, smallAreaChartOpts, barCharOpts } from "@/utils/chart";

export default {
  name: "FacebookOverview",
  components: {
    ChartWrapper: () =>
      import("@/components/").then((res) => {
        return res.ChartWrapper;
      }),
  },
  props: {
    selectedInterval: { type: String, default: "weekly" },
    metrics: { type: Array, default: () => [] },
  },
  computed: {
    reachConf() {
      const reachImpressions =
        (this.metrics.filter(
          (metric) => metric.type === "page_posts_impressions",
        ) || [])[0] || {};

      const { data = [] } = reachImpressions;

      const count = data.reduce((acc, curr) => {
        return acc + curr.count;
      }, 0);

      return {
        count,
      };
    },
    impressionsConf() {
      const pageImpressionsCount =
        (this.metrics.filter((metric) => metric.type === "page_impressions") ||
          [])[0] || {};

      const { data = [] } = pageImpressionsCount;

      const count = data.reduce((acc, curr) => {
        return acc + curr.count;
      }, 0);

      return {
        count,
      };
    },
    newLikesConf() {
      const likeCountsMetrics =
        (this.metrics.filter(
          (metric) => metric.type === "page_positive_feedback_by_type",
        ) || [])[0] || {};

      const { data = [] } = likeCountsMetrics;

      const count = data.reduce((acc, curr) => {
        if (curr.count["like"]) {
          return acc + curr.count["like"];
        }
        return acc;
      }, 0);

      return {
        count,
      };
    },
    currentLikesConf() {
      const pageFansMetrics =
        (this.metrics.filter((metric) => metric.type === "total_page_fans") ||
          [])[0] || {};

      const { data = [] } = pageFansMetrics;

      const count = (data[0] || {}).count || 0;

      return {
        count,
      };
    },
    impressionsSeries() {
      const impressionMetrics =
        (this.metrics.filter((metric) => metric.type === "page_impressions") ||
          [])[0] || {};

      const { data = [] } = impressionMetrics;

      const xaxis = data.map((d) => d.date);

      const seriesObj = data.reduce(
        (acc, curr) => {
          const j = xaxis.indexOf(curr.date);
          acc[j] = curr.count;

          return acc;
        },
        Array.from(xaxis).map((o) => 0),
      );

      const series = [
        {
          name: "Organic impressions",
          data: seriesObj,
        },
      ];

      return {
        series,
        chartOptions: {
          ...areaChartOpts,
          xaxis: {
            ...areaChartOpts.xaxis,
            categories: xaxis,
          },
        },
      };
    },
    reachSeries() {
      const pageImpressionMetrics =
        (this.metrics.filter(
          (metric) => metric.type === "page_posts_impressions",
        ) || [])[0] || {};

      const { data = [] } = pageImpressionMetrics;

      const xaxis = data.map((d) => d.date);

      const seriesObj = data.reduce(
        (acc, curr) => {
          const j = xaxis.indexOf(curr.date);
          acc[j] = curr.count;

          return acc;
        },
        Array.from(xaxis).map((o) => 0),
      );

      const series = [
        {
          name: "Organic impressions",
          data: seriesObj,
        },
      ];

      return {
        series,
        chartOptions: {
          ...areaChartOpts,
          xaxis: {
            ...areaChartOpts.xaxis,
            categories: xaxis,
          },
        },
      };
    },
    newLikesSeries() {
      const newLikeMetrics =
        (this.metrics.filter(
          (metric) => metric.type === "page_posts_impressions",
        ) || [])[0] || {};

      const { data = [] } = newLikeMetrics;

      const xaxis = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

      const seriesObj = Object.values(
        xaxis.reduce((acc, curr) => {
          acc[curr] = data
            .filter((d) => dayjs(d.date).format("ddd") == curr)
            .reduce((a, c) => {
              if (c.count && c.count["like"]) {
                return a + c.count["like"];
              }
              return a;
            }, 0);
          return acc;
        }, {}),
      );

      const series = [
        {
          name: "New Likes",
          data: seriesObj,
        },
      ];

      return {
        series,
        chartOptions: {
          ...barCharOpts,
          xaxis: {
            ...barCharOpts.xaxis,
            categories: xaxis,
          },
        },
      };
    },
    unlikeSeries() {
      const unlikeMetrics =
        (this.metrics.filter(
          (metric) => metric.type === "page_negative_feedback_by_type",
        ) || [])[0] || {};

      const { data = [] } = unlikeMetrics;
      const xaxis = data.map((d) => d.date);

      const seriesObj = data.reduce(
        (acc, curr) => {
          if (curr.count && curr.count["unlike_page_clicks"]) {
            const j = xaxis.indexOf(curr.date);
            acc[j] = curr.count["unlike_page_clicks"];
          }

          return acc;
        },
        Array.from(xaxis).map((o) => 0),
      );

      const series = [
        {
          name: "Page unlikes",
          data: seriesObj,
        },
      ];

      return {
        series,
        chartOptions: {
          ...areaChartOpts,
          xaxis: {
            ...areaChartOpts.xaxis,
            categories: xaxis,
          },
        },
      };
    },
    visitsSeries() {
      const visitMetrics =
        (this.metrics.filter((metric) => metric.type === "page_views_total") ||
          [])[0] || {};

      const { data = [] } = visitMetrics;

      const xaxis = data.map((d) => d.date);

      const seriesObj = data.reduce(
        (acc, curr) => {
          const j = xaxis.indexOf(curr.date);
          acc[j] = curr.count;

          return acc;
        },
        Array.from(xaxis).map((o) => 0),
      );

      const series = [
        {
          name: "Organic impressions",
          data: seriesObj,
        },
      ];

      return {
        series,
        chartOptions: {
          ...areaChartOpts,
          xaxis: {
            ...areaChartOpts.xaxis,
            categories: xaxis,
          },
        },
      };
    },
    pageCommentsSeries() {
      const commentMetrics =
        (this.metrics.filter(
          (metric) => metric.type === "page_positive_feedback_by_type",
        ) || [])[0] || {};

      const { data = [] } = commentMetrics;

      const xaxis = data.map((d) => d.date);

      const seriesObj = data.reduce(
        (acc, curr) => {
          if (curr.count && curr.count["comment"]) {
            const j = xaxis.indexOf(curr.date);
            acc[j] = curr.count["comment"];
          }

          return acc;
        },
        Array.from(xaxis).map((o) => 0),
      );

      const series = [
        {
          name: "Comments",
          data: seriesObj,
        },
      ];

      const count = data.reduce((acc, curr) => {
        if (curr.count && curr.count["comment"]) {
          return acc + curr.count["comment"];
        }

        return acc;
      }, 0);

      return {
        series,
        count,
        chartOptions: {
          ...smallAreaChartOpts,
          xaxis: {
            ...smallAreaChartOpts.xaxis,
            categories: xaxis,
          },
        },
      };
    },
    pageSharesSeries() {
      const shareMetrics =
        (this.metrics.filter(
          (metric) => metric.type === "page_positive_feedback_by_type",
        ) || [])[0] || {};

      const { data = [] } = shareMetrics;

      const xaxis = data.map((d) => d.date);

      const seriesObj = data.reduce(
        (acc, curr) => {
          if (curr.count && curr.count["link"]) {
            const j = xaxis.indexOf(curr.date);
            acc[j] = curr.count["link"];
          }

          return acc;
        },
        Array.from(xaxis).map((o) => 0),
      );

      const series = [
        {
          name: "Shares",
          data: seriesObj,
        },
      ];

      const count = data.reduce((acc, curr) => {
        if (curr.count && curr.count["link"]) {
          return acc + curr.count["link"];
        }

        return acc;
      }, 0);

      return {
        series,
        count,
        chartOptions: {
          ...smallAreaChartOpts,
          xaxis: {
            ...smallAreaChartOpts.xaxis,
            categories: xaxis,
          },
        },
      };
    },
    postLinkSeries() {
      const postLinkMetrics =
        (this.metrics.filter(
          (metric) => metric.type === "page_consumptions_by_consumption_type",
        ) || [])[0] || {};

      const { data = [] } = postLinkMetrics;

      const xaxis = data.map((d) => d.date);

      const seriesObj = data.reduce(
        (acc, curr) => {
          if (curr.count && curr.count["link clicks"]) {
            const j = xaxis.indexOf(curr.date);
            acc[j] = curr.count["link clicks"];
          }

          return acc;
        },
        Array.from(xaxis).map((o) => 0),
      );

      const series = [
        {
          name: "Shares",
          data: seriesObj,
        },
      ];

      const count = data.reduce((acc, curr) => {
        if (curr.count && curr.count["link clicks"]) {
          return acc + curr.count["link clicks"];
        }

        return acc;
      }, 0);

      return {
        series,
        count,
        chartOptions: {
          ...smallAreaChartOpts,
          xaxis: {
            ...smallAreaChartOpts.xaxis,
            categories: xaxis,
          },
        },
      };
    },
    postReactionsSeries() {
      const reactionMetrics =
        (this.metrics.filter(
          (metric) => metric.type === "page_actions_post_reactions_total",
        ) || [])[0] || {};

      const { data = [] } = reactionMetrics;

      const xaxis = data.map((d) => d.date);

      const seriesObj = data.reduce(
        (acc, curr) => {
          if (Object.values(curr.count) > 0) {
            const j = xaxis.indexOf(curr.date);
            acc[j] = Object.values(curr.count).reduce((a, c) => {
              return a + c;
            }, 0);
          }

          return acc;
        },
        Array.from(xaxis).map((o) => 0),
      );

      const series = [
        {
          name: "Reactions",
          data: seriesObj,
        },
      ];

      const count = data.reduce((acc, curr) => {
        acc =
          acc +
          Object.values(curr.count).reduce((a, c) => {
            return a + c;
          }, 0);
        return acc;
      }, 0);

      return {
        series,
        count,
        chartOptions: {
          ...smallAreaChartOpts,
          xaxis: {
            ...smallAreaChartOpts.xaxis,
            categories: xaxis,
          },
        },
      };
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.section {
  > div {
    flex: 1;
  }
}
</style>
